import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto p-6 max-w-4xl flex justify-center">
      <div className="bg-white shadow-lg rounded-2xl p-8 w-full" style={{    padding:"30px"}}>
        <h1 className="text-3xl font-bold mb-4 text-center">Privacy Policy</h1>
        <p className="mb-4 text-center">Effective Date: Wed 5 March 2025</p>
        
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">1. Introduction</h2>
          <p>
            Welcome to <strong>arthroscopydoc.com</strong>, operated by Dr. Sunil Apsingi.
            Your privacy is important to us. This Privacy Policy outlines the types of
            information we collect, how we use it, and how we protect your information.
          </p>
        </section>
        
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">2. Information We Collect</h2>
          <ul className="list-disc ml-6" style={{paddingLeft:"30px", paddingBottom:"30px",listStyleType:"disc"}} >
            <li>Personal Information: Name, email, phone number (if provided).</li>
            <li>Non-Personal Information: Browser type, IP address, pages visited.</li>
            <li>Cookies and tracking technologies for analytics.</li>
          </ul>
        </section>
        
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">3. How We Use Your Information</h2>
          <ul className="list-disc ml-6" style={{paddingLeft:"30px", paddingBottom:"30px", listStyleType:"disc"}}>
            <li>To improve website functionality and user experience.</li>
            <li>To communicate with you regarding inquiries.</li>
            <li>To analyze website traffic and trends.</li>
          </ul>
        </section>
        
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">4. Information Sharing</h2>
          <p>
            We do not sell or share your personal data with third parties, except when required
            by law or to improve our services (such as analytics providers).
          </p>
        </section>
        
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">5. Security</h2>
          <p>
            We take reasonable measures to protect your data. However, no online platform
            can guarantee complete security.
          </p>
        </section>
        
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">6. Your Rights</h2>
          <p>
            You have the right to access, update, or request deletion of your personal data.
            Contact us at <strong>sunilapsingi@arthroscopydoc.com</strong> for any requests.
          </p>
        </section>
        
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">7. Changes to This Policy</h2>
          <p>
            We may update this Privacy Policy periodically. Please review this page for
            the latest information.
          </p>
        </section>
        
        <section>
          <h2 className="text-xl font-semibold mb-2">8. Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact us at
            <strong> sunilapsingi@arthroscopydoc.com </strong>.
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
