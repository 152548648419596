// import logo from './logo.svg';
import './App.css';
import Blogs from './components/Blogs';
import BlogDetail from "./components/BlogDetail";
import BookAppointment from './components/BookAppointment';
import Contact from './components/Contact';
import Footer from './components/Footer';
import GallerySection from './components/GallerySection';
import Header from './components/Header';
import Health from './components/Health';
import Knowledge from './components/Knowledge';
import NavBar from './components/NavBar';
import Reviews from './components/Reviews';
import Services from './components/Services';
import UsefulLinks from './components/UsefulLinks';
import WhyUs from './components/WhyUs';
import PrivacyPolicy from './components/PrivacyPolicy';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import DoctorProfile from './components/DoctorProfile';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header></Header>
                <Health></Health>
                <Knowledge></Knowledge>
                <WhyUs></WhyUs>
                <Services></Services>
                <Reviews></Reviews>
                <Blogs showAll={false} ></Blogs>
                <Footer></Footer>
              </>
            }
          ></Route>
          <Route
            // exact
            path="/aboutus"
            element={
            <>
            <NavBar/>
              <DoctorProfile />
              <Knowledge></Knowledge>
              <Footer /></>}
            ></Route>
            <Route
            // exact
            path="/services"
            element={<><NavBar></NavBar><Services showAll={true} /><Footer></Footer></>}
          ></Route>
          <Route
            // exact
            path="/blogs"
            element={<><NavBar></NavBar><Blogs showAll={true} /><Footer></Footer></>}
          ></Route>
          <Route
            // exact
            path="/contact"
            element={<><NavBar></NavBar><Contact /><Footer></Footer></>}
          ></Route>
          <Route
            // exact
            path="/privacy-policy"
            element={<><NavBar></NavBar><PrivacyPolicy /><Footer></Footer></>}
          ></Route>
          <Route
            // exact
            path="/usefullinks"
            element={<><NavBar></NavBar><UsefulLinks /><Footer></Footer></>}
          ></Route>
          <Route
            // exact
            path="/profile"
            element={<><NavBar></NavBar><DoctorProfile /><Footer></Footer></>}
          ></Route>
          <Route
            path="/gallery"
            element={<GallerySection></GallerySection>}
          ></Route>
          <Route
            path="/bookappointment"
            element={<><NavBar></NavBar><BookAppointment /><Footer></Footer></>}
          ></Route>
          <Route path="/blogs/:id" element={<><NavBar></NavBar><BlogDetail /><Footer></Footer></>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

